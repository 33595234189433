/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from "axios";
import { useMutation, UseMutationResult } from "react-query";
import {
    IRevokeProjectAdminRightsResponse,
    IRevokeProjectAdminRightsRequest
} from "../types/managed-process/revokeProjectAdminRights";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { protectedResources } from "../auth/authConfig";

const createRevokeProjectAdminRights = async (
    accessToken: string,
    request: IRevokeProjectAdminRightsRequest,
    projectSlug: string
): Promise<IRevokeProjectAdminRightsResponse> => {
    const response = await axios({
        method: "POST",
        url: `${protectedResources.wavecore.endpoint}/api/RevokeProjectAdminRights/${projectSlug}`,
        data: JSON.stringify(request),
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useCreateRevokeProjectAdminRights = (
    projectSlug: string
): UseMutationResult<IRevokeProjectAdminRightsResponse, unknown, IRevokeProjectAdminRightsRequest, unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(
        protectedResources.wavecore.scopes
    );
    return useMutation((vars) =>
        getAccessToken().then((accessToken) => {
            if (accessToken && isAuthenticated) {
                return createRevokeProjectAdminRights(accessToken, vars, projectSlug);
            }
            return Promise.reject();
        })
    );
};

export default useCreateRevokeProjectAdminRights;
