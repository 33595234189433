import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Checkbox, Text } from "@breakingwave/react-ui-components";
import Layout from "../../../../components/layout";
import Container from "../../../../components/grid/Container";
import Row from "../../../../components/grid/Row";
import Column from "../../../../components/grid/Column";
import { useOnboardingContext } from "../_state/context";
import Spinner from "../../../../components/spinner";
import { TaskItemStatusEnum } from "../../../../types/shared";
import { AttestationTargetTypeEnum, ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../../types/onboarding";
import useNotifyAutomationService from "../../../../api/notifyAutomationService";
import { IProjectTeamAssignmentResponse } from "../../../../types/managed-process/projectTeamAssignment";
import useGetManagedProcessTaskStatus from "../../../../api/getManagedProcessTaskStatus";
import { htmlHeadProps, headerProps, footerProps } from "./Attestation.config";
import BreakingWaveV1 from "./BreakingWaveV1";
import SynthixV1 from "./SynthixV1";
import GuestsV1 from "./GuestsV1";
import * as S from "./Attestation.styles";

export interface IAttestationProps {
    projectTeamAssignment: IProjectTeamAssignmentResponse;
}

const Attestation: React.FC = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [confirmAttestationCheckbox, setConfirmAttestationCheckbox] = useState(false);
    const { state } = useOnboardingContext();

    const {
        ManagedContext,
        ManagedProcess: projectTeamAssignment,
        AccessToken: OnboardingAccessToken
    } = state;

    const projectSlug = useMemo(() => projectTeamAssignment?.ProjectSlug ?? "",[projectTeamAssignment]);    
    const attestationAccessToken = useMemo(() => projectTeamAssignment?.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.ATTESTATION_COMPLETE.toString())?.AccessToken, []);
    const trainingAccessToken = useMemo(() => projectTeamAssignment?.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.TRAINING_COMPLETE.toString())?.AccessToken, []);

    const {
        isLoading: isSubmittingAttestation,
        mutateAsync: submitAttestation,
        isSuccess: isSubmitAttestationSuccess
    } = useNotifyAutomationService(ManagedContext, projectSlug, attestationAccessToken, ProjectTeamAssignmentTaskAutomationKeyEnum.ATTESTATION_COMPLETE);

    const {
        isFetching: isGetTrainingTaskStatusFetching,
        data: trainingTaskStatusResult
    } = useGetManagedProcessTaskStatus(
        ManagedContext,
        projectSlug,
        ProjectTeamAssignmentTaskAutomationKeyEnum.TRAINING_COMPLETE,
        trainingAccessToken,
        {
            enabled: !!ManagedContext.Id &&                      
                     !!projectSlug &&
                     !!ManagedContext.ResourceType && 
                     !!trainingAccessToken && 
                     isSubmitAttestationSuccess,
            refetchInterval: 2500
        }
    );

    useEffect(() => {        
        const { id, t } = Object.fromEntries([...searchParams]); 

        // If the user hit F5 then the managed process will not be
        // in state and so we need to go back to the landing page.        
        if (!projectTeamAssignment) {
            if (id && t) {
                console.log("Navigating from 'Attestation' to 'Onboarding Landing'.  User probably hit F5");
                navigate(`/onboarding?id=${id}&t=${t}`);
            } else {
                console.log("Parameters error. Redirecting to root.");
                navigate("/");
            }
        }
    }, []);

    useEffect(() => {
        // The attestation has been submitted.
        // Spin until the training task is ready before navigating
        // to the training page.
        if (!isSubmitAttestationSuccess) {
            console.log("Can't go to training. 'isSubmitAttestationSuccess' is false");
            return;
        }

        if (!trainingTaskStatusResult) {
            console.log("Can't go to training. 'trainingTastStatusResult' is undefined");
            return;
        }

        if (!isGetTrainingTaskStatusFetching) {
            console.log("Can't go to training. 'isGetTrainingTaskStatusFetching' is fetching");
            return;
        }

        if (trainingTaskStatusResult.TaskStatus === TaskItemStatusEnum.Complete) {
            console.log("Training is already complete.. going back to onboarding landing");
            navigate(`/onboarding?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
            return;
        }     

        if (trainingTaskStatusResult.TaskStatus === TaskItemStatusEnum.NotApplicable) {
            console.log("Training is not applicable.. going back to onboarding landing");
            navigate(`/onboarding?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
            return;
        }             

        if (trainingTaskStatusResult.TaskStatus !== TaskItemStatusEnum.Pending) {
            console.log(`Can't go to training.  Training Task Status: ${trainingTaskStatusResult?.TaskStatus}`);
            return;
        }

        console.log("Going to training...");
        navigate(`/training-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);

    }, [isSubmitAttestationSuccess, trainingTaskStatusResult, isGetTrainingTaskStatusFetching]);

    const confirmAttestation = async (): Promise<void> => {
        await submitAttestation(null);
    };

    return (
        <>
            {(isGetTrainingTaskStatusFetching || isSubmitAttestationSuccess) && (
                <S.LoadingSection>
                    <Spinner padding="20px" />
                    <h1>Your signed attestation has been submitted. Please wait while we get your next task ready.</h1>
                </S.LoadingSection>
            )}
            {!isGetTrainingTaskStatusFetching && !isSubmitAttestationSuccess && projectTeamAssignment && (                
                <Layout htmlHeadProps={htmlHeadProps} headerProps={headerProps} footerProps={footerProps}>
                    <>
                        {projectTeamAssignment.AttestationTarget === AttestationTargetTypeEnum.BreakingWaveV1 && <BreakingWaveV1 projectTeamAssignment={projectTeamAssignment} />}
                        {projectTeamAssignment.AttestationTarget === AttestationTargetTypeEnum.SynthixV1 && <SynthixV1 projectTeamAssignment={projectTeamAssignment} />}
                        {projectTeamAssignment.AttestationTarget === AttestationTargetTypeEnum.GuestV1 && <GuestsV1 />}
                        
                        {Object.values(AttestationTargetTypeEnum).includes(projectTeamAssignment.AttestationTarget as AttestationTargetTypeEnum) ?
                            <S.ConfirmationSection>
                                <Container isResponsive={true}>
                                    <Row>
                                        <Column md={7}>
                                            <S.AttestationCheckboxWrapper>
                                                <Checkbox
                                                    name="confirmAttestationCheck"
                                                    defaultChecked={confirmAttestationCheckbox}
                                                    disabled={isSubmittingAttestation}
                                                    onClick={() => setConfirmAttestationCheckbox(!confirmAttestationCheckbox)}
                                                >
                                                    I confirm that I understand my obligations relating to obtaining access to
                                                    the Breaking Wave {projectTeamAssignment.AttestationTarget === AttestationTargetTypeEnum.GuestV1 ? "Collaboration Box" : "Community"}, in accordance with the information outlined
                                                    above.
                                                </Checkbox>
                                            </S.AttestationCheckboxWrapper>
                                            <Button
                                                onClick={confirmAttestation}
                                                isLoading={isSubmittingAttestation}
                                                disabled={!confirmAttestationCheckbox || isSubmittingAttestation}
                                            >
                                            Confirm
                                            </Button>
                                        </Column>
                                    </Row>
                                </Container>
                            </S.ConfirmationSection> 
                            :
                            <S.ErrorWrapper>
                                <h1>
                                    Unsupported Attestation Target {projectTeamAssignment.AttestationTarget}  
                                </h1>
                                <Text variant="p">
                                    Please contact support
                                </Text>                                            
                            </S.ErrorWrapper>   
                        }
                    </>
                </Layout>
            )}
        </>
    );
};

export default Attestation;
