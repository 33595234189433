import axios from "axios";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { protectedResources } from "../auth/authConfig";
import useAadAccessToken from "../hooks/useAadAccessToken";
import { IJoinProjectMemberEligibility } from "../types/managed-process/joinProject";
import { QueryKeysEnum } from "../types/queryKeys";

const getJoinProjectMemberEligibility = async (
    accessToken: string,
    projectSlug: string
): Promise<IJoinProjectMemberEligibility[]> => {
    const url = `${protectedResources.wavecore.endpoint}/api/JoinProjects/${projectSlug}/MemberEligibility`;
    const response = await axios({
        method: "GET",
        url: url,
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    });

    return response.data;
};

const useGetJoinProjectMemberEligibility = (
    projectSlug: string,
    options?: UseQueryOptions<IJoinProjectMemberEligibility[], unknown, IJoinProjectMemberEligibility[], string[]>
): UseQueryResult<IJoinProjectMemberEligibility[], unknown> => {
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    return useQuery(
        [QueryKeysEnum.joinProjectMemberEligibility, projectSlug], 
        () => 
            getAccessToken().then((accessToken) => {
                if (accessToken && isAuthenticated) {
                    return getJoinProjectMemberEligibility(accessToken, projectSlug);
                }
                return Promise.reject();
            }),
        {
            ...options
        }
    );
};

export default useGetJoinProjectMemberEligibility;
