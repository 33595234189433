import styled from "styled-components";

const LoadingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    div:first-child {
        height: auto;
    }
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h1 {
        font-size: 1.5em;
    }
`;

const PageTitleSection = styled.section`
    background-color: #000;
    margin-bottom: 32px;
`;

const ProjectInfoGroup = styled.div`
    display: flex;

    & > :not(div:last-child) {
        margin-right: 64px;
    }
`;

const BodySection = styled.section`
    background-color: ${({ theme }) => theme.colors.whitelabel.bodySectionBackground};
    padding: 56px 0;
`;

const RequestDetailsList = styled.ul`
    margin-bottom: 64px;
`;

const RequestDetailsListItem = styled.li`
    display: flex;
    padding: 20px 12px;
    border-bottom: 1px solid #595959;

    > * {
        &:first-child {
            min-width: 50%;
        }
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: 12px;
`;

// TODO: Make this one a component on its own
const Hr = styled.hr<{ type?: string; color?: string; hidden?: boolean }>`
    border-top: 1px ${({ type }) => type ?? "solid"} ${({ color }) => color ?? "#000"};
    background-color: transparent;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

export {
    LoadingSection,
    ErrorWrapper,
    PageTitleSection,
    ProjectInfoGroup,
    BodySection,
    RequestDetailsList,
    RequestDetailsListItem,
    ButtonsContainer,
    Hr
};
