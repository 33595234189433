/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
// eslint-disable-next-line import/named
import { DefaultTheme } from "styled-components";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { breakingWaveTheme, synthixTheme } from "@breakingwave/react-ui-components";


interface IAppState {
    theme: DefaultTheme;
}

const initialAppState: IAppState = {
    theme: breakingWaveTheme
};

export enum AppActionTypesEnum {
    SET_THEME = "SET_THEME",
}

export interface ISetTheme {
    type: AppActionTypesEnum.SET_THEME;
    payload: string;
}

export type AppActions = ISetTheme;

const reducer: React.Reducer<IAppState, AppActions> = (state, action) => {
    switch (action.type) {

        case AppActionTypesEnum.SET_THEME: {
            return {
                ...state,
                // TODO: use synthix theme when we're ready
                //theme: action.payload === "Synthix" ? synthixTheme : breakingWaveTheme
                theme: breakingWaveTheme
            };
        }

        default:
            return state;
    }
};

export interface IAppContextProps {
    state: IAppState;
    dispatch: React.Dispatch<AppActions>;
}

export const AppContext = React.createContext<IAppContextProps>({
    state: initialAppState,
    dispatch: () => null
});

export function useAppContext (): IAppContextProps {
    return React.useContext(AppContext);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const AppProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialAppState);
    const value = { state, dispatch };
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
