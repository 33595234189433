/* eslint-disable @typescript-eslint/naming-convention */
import { EndDateModeEnum, MemberTypeEnum, RateCurrencyEnum, RateTypeEnum } from "../shared";
import { IMemberManagedProcessBase } from "./managedProcessBase";

export enum ProjectAdminAssignmentTaskAutomationKeyEnum {
    PROJECT_ADMIN_TERMS_AGREED = "PROJECT_ADMIN_TERMS_AGREED",
    SEND_PROJECT_ADMIN_REQUEST_EMAIL = "SEND_PROJECT_ADMIN_REQUEST_EMAIL",
}

export interface IProjectAdminAssignment {
    MemberType: MemberTypeEnum;
    FirstName: string;
    LastName: string;
    ContactEmailAddress: string;
    UserPrincipalName: string | undefined;
}

export interface IProjectAdminAssignmentResponse extends IMemberManagedProcessBase {
    ProjectName: string;
    MemberType: MemberTypeEnum;
    Country: string;
    CountryIso: string;
    SubscriptionType: string;
    StartDate: string;
    EndDate?: string;
    EndDateMode: EndDateModeEnum;
    Rate: number;
    RateType: RateTypeEnum;
    RateCurrency: RateCurrencyEnum;
    Status: string; // TODO: Change it to enum types. Find statuses from the state machine doc
    StatusDateTimeUtc: string;
    HomeProjectSlug: string;
}

export enum CreateProjectAdminAssignmentDomainValidationFailureReasonEnum {
    Success = 0,
    ProjectNotFound = 1 << 0, // 1
    AuthenticatedUserDoesNotHavePermission = 1 << 1, // 2
    ContactEmailDomainDoesNotMatchProjectDomain = 1 << 2, // 4
    AlreadyProjectAdminOfThisProject = 1 << 3, // 8
    ProvidedUpnDidNotReturnUserButContactEmailDid = 1 << 4, // 16
    ContactEmailsDontMatch = 1 << 5, // 32
    InFlightProjectTeamAssignments = 1 << 6, // 64
    InFlightProjectAdminAssignments = 1 << 7, // 128
    InFlightJoinProjects = 1 << 8, // 256
    InFlightLeaveProjects = 1 << 9, // 512
    InFlightOffboardings = 1 << 10, // 1024
}

export interface IProjectAdminAssignmentErrorResponse {
    ValidationResult: CreateProjectAdminAssignmentDomainValidationFailureReasonEnum;
}
