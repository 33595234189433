import React from "react";
import { useProjectPageContext } from "../_state/context";
import { ProjectTeamMemberStateEnum } from "../../../../types/managed-process/projectTeamAssignment";
import { IProjectTeamMemberDisplayItemsResponse } from "../../../../types/teamMemberDisplayItems";
import TeamMemberGroupStatuses from "./team-member-group-statuses";
import TeamMemberWithContextMenuWizard from "./team-member-with-context-menu";
import OffboardTeamMemberWizard from "./wizards/offboard-team-member-wizard";
import LeaveProjectWizard from "./wizards/leave-project-wizard";
import ProjectAdminAssignmentWizard from "./wizards/project-admin-assignment-wizard";
import RevokeProjectAdminRightsWizard from "./wizards/revoke-project-admin-rights-wizard";
import { TeamMemberGroupStatusColourModeEnum } from "./team-member-group-statuses/index.colours";
import IndeterminateTeamMember from "./indeterminate-team-member";

const TeamMembers: React.FC<{
    data: IProjectTeamMemberDisplayItemsResponse[];
    searchTerm: string;
}> = ({ data: teamMembers, searchTerm }) => {
    const {
        state: projectPageState
    } = useProjectPageContext();

    const handleSearchFilter = (f: IProjectTeamMemberDisplayItemsResponse): boolean => {
        const sanitizedSearchTerm = searchTerm?.toLowerCase().trim();
        const fullName = `${f.FirstName} ${f.LastName}`;
        return fullName.toLowerCase().includes(sanitizedSearchTerm);
    };

    const {
        selectedProject: { ProjectSlug },
        projectAdminAssignmentWizard: { tempProjectAdminAssignmentStepsData, lastSavedAdminAssignmentUPN },
        revokeProjectAdminRightsWizard: { tempRevokeProjectAdminRightsStepsData, lastSavedRevokeUPN },
        teamAssignmentWizard: { tempTeamAssignmentStepsData },
        leaveProjectWizard: { tempLeavingMemberData, lastSavedLeaveUPN },
        offboardTeamMemberWizard: { tempOffboardingMemberData, lastSavedOffboardUPN },
        joinProjectWizard: { lastSavedJoinUPN }
    } = projectPageState;

    return (
        <>       
            {teamMembers
                ?.filter(handleSearchFilter)
                .sort((a, b) => (a.State === "Onboarding" ? -1 : b.State === "Onboarding" ? 1 : 0))
                .map((teamMember: IProjectTeamMemberDisplayItemsResponse) => {
                    if (teamMember.State === ProjectTeamMemberStateEnum.Indeterminate) {
                        return (
                            <IndeterminateTeamMember 
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                data={teamMember} />
                        );
                    }                      
                    if (teamMember.State === ProjectTeamMemberStateEnum.AdminAssignment) {
                        return (
                            <TeamMemberGroupStatuses
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                colourMode={TeamMemberGroupStatusColourModeEnum.PROJECT_ADMIN_ASSIGNMENT_GROUP_STATUSES_MODE}
                                data={teamMember}
                                defaultOpenState={lastSavedAdminAssignmentUPN === teamMember.UserPrincipalName}
                            />
                        );
                    }            
                    if (teamMember.State === ProjectTeamMemberStateEnum.Onboarding) {
                        return (
                            <TeamMemberGroupStatuses
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                colourMode={TeamMemberGroupStatusColourModeEnum.TEAM_MEMBER_DISPLAY_ITEMS_GROUP_STATUSES_MODE}
                                data={teamMember}
                                defaultOpenState={tempTeamAssignmentStepsData?.ContactEmailAddress === teamMember.ContactEmailAddress}
                            />
                        );
                    }
                    if (teamMember.State === ProjectTeamMemberStateEnum.Member) {
                        if (tempOffboardingMemberData?.UserPrincipalName === teamMember.UserPrincipalName) {
                            return (
                                <OffboardTeamMemberWizard
                                    key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                    data={teamMember}
                                />
                            );
                        } else if (tempLeavingMemberData?.UserPrincipalName === teamMember.UserPrincipalName) {
                            return (
                                <LeaveProjectWizard
                                    key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                    data={teamMember}
                                />
                            );
                        } else if (tempProjectAdminAssignmentStepsData?.UserPrincipalName === teamMember.UserPrincipalName) {
                            return (
                                <ProjectAdminAssignmentWizard
                                    key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                />
                            );
                        } else if (tempRevokeProjectAdminRightsStepsData?.UserPrincipalName === teamMember.UserPrincipalName) {
                            return (
                                <RevokeProjectAdminRightsWizard
                                    key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                    data={teamMember}
                                />
                            );                            
                        } else {
                            // Default state if member is not participating in any wizard
                            return (
                                <TeamMemberWithContextMenuWizard
                                    key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                    data={teamMember}
                                    defaultOpenState={false}
                                />
                            );
                        }
                    }
                    if (teamMember.State === ProjectTeamMemberStateEnum.Offboarding) {
                        return (
                            <TeamMemberGroupStatuses
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                colourMode={TeamMemberGroupStatusColourModeEnum.OFFBOARD_TEAM_MEMBER_GROUP_STATUSES_MODE}
                                data={teamMember}
                                defaultOpenState={lastSavedOffboardUPN === teamMember.UserPrincipalName}
                            />
                        );
                    }
                    if (teamMember.State === ProjectTeamMemberStateEnum.Leaving) {
                        return (
                            <TeamMemberGroupStatuses
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                colourMode={TeamMemberGroupStatusColourModeEnum.LEAVE_PROJECT_GROUP_STATUSES_MODE}
                                data={teamMember}
                                defaultOpenState={lastSavedLeaveUPN === teamMember.UserPrincipalName}
                            />
                        );
                    }
                    if (teamMember.State === ProjectTeamMemberStateEnum.Joining) {
                        return (
                            <TeamMemberGroupStatuses
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                colourMode={TeamMemberGroupStatusColourModeEnum.JOIN_PROJECT_GROUP_STATUSES_MODE}
                                data={teamMember}
                                defaultOpenState={lastSavedJoinUPN === teamMember.UserPrincipalName}
                            />
                        );
                    }            
                    if (teamMember.State === ProjectTeamMemberStateEnum.RevokingAdminRights) {
                        return (
                            <TeamMemberGroupStatuses
                                key={`${ProjectSlug}-${teamMember.State}-${teamMember.UserPrincipalName}`}
                                colourMode={TeamMemberGroupStatusColourModeEnum.REVOKE_PROJECT_ADMIN_RIGHTS_GROUP_STATUSES_MODE}
                                data={teamMember}
                                defaultOpenState={lastSavedRevokeUPN === teamMember.UserPrincipalName}
                            />
                        );                   
                    }                               
                    return false;
                })}
        </>
    );
};

export default TeamMembers;
