import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../../../components/spinner";
import { ManagedProcessResourceTypeEnum, TaskGroupStatusesEnum } from "../../../../types/shared";
import { useOnboardingContext } from "../_state/context";
import { OnboardingActionTypesEnum } from "../_state/actions";
import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../../types/onboarding";
import { IProjectTeamAssignmentResponse } from "../../../../types/managed-process/projectTeamAssignment";
import getOnboardingTaskStatusLoadingDescription from "../../../../utils/getOnboardingTaskStatusLoadingDescription";
import useGetManagedProcessByAccessToken from "../../../../api/getManagedProcessByAccessToken";
import useWaveTheme from "../../../../hooks/useWaveTheme";
import * as S from "./OnboardingLandingPage.styles";

const OnboardingLandingPage: React.FC = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams(); 
    const [errorMessage, setErrorMessage] = useState<string>();

    const { 
        state: {
            ManagedContext,
            ManagedProcess: projectTeamAssignment,
            AccessToken: OnboardingAccessToken,
            ProcessingTask: automationKey
        },
        dispatch 
    } = useOnboardingContext();

    const {
        data: managedProcessResult,
        isSuccess: managedProcessLoadSuccess
    } = useGetManagedProcessByAccessToken<IProjectTeamAssignmentResponse>(ManagedContext, ProjectTeamAssignmentTaskAutomationKeyEnum.SEND_ONBOARDING_REQUEST_EMAIL, OnboardingAccessToken, {
        enabled: !!(ManagedContext && OnboardingAccessToken) && !errorMessage,
        cacheTime: 0,
        refetchInterval: 2500
    });

    useEffect(() => {
        const timeOut = setTimeout(() => {
            setErrorMessage("This link may have expired.");
        }, 60000 * 3);

        return (() => {
            console.log("Clearing onboarding landing page timeout");
            clearTimeout(timeOut);
        });
    }, []);

    useEffect(() => {
        const { id, t } = Object.fromEntries([...searchParams]);

        if (id && t) {
            dispatch({
                type: OnboardingActionTypesEnum.SET_ONBOARDING_CONTEXT,
                payload: {
                    Id: id,
                    ResourceType: ManagedProcessResourceTypeEnum.ProjectTeamAssignment
                }
            });

            dispatch({
                type: OnboardingActionTypesEnum.SET_ONBOARDING_ACCESSTOKEN,
                payload: t
            });            
        } else {
            setErrorMessage("Parameters invalid");
        }    
    }, []);

    useEffect(() => {
        if (managedProcessResult && ManagedContext) {
            dispatch({
                type: OnboardingActionTypesEnum.SET_ONBOARDING_MANAGED_PROCESS,
                payload: managedProcessResult
            });
        }
    }, [managedProcessResult, ManagedContext]);

    useWaveTheme(managedProcessResult);

    useEffect(() => {
        if (managedProcessResult && projectTeamAssignment && managedProcessLoadSuccess) {
            const attestationTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.ATTESTATION_COMPLETE.toString());
            if (!attestationTask) {
                setErrorMessage("Attestation task is missing");
                return;
            }

            if (attestationTask?.Status === TaskGroupStatusesEnum.Pending) {
                console.log("Navigating to 'attestation'");
                navigate(`/attestation-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }

            const trainingTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.TRAINING_COMPLETE.toString());
            if (!trainingTask) {
                setErrorMessage("Training task is missing");
                return;
            }

            if (trainingTask.Status === TaskGroupStatusesEnum.Pending) {
                console.log("Navigating to 'training'");
                navigate(`/training-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }

            const deviceRequirementTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.DEVICE_REQUIREMENT_COMPLETE.toString());
            if (!deviceRequirementTask) {
                setErrorMessage("Device Requirement task is missing");
                return;
            }

            if (deviceRequirementTask.Status === TaskGroupStatusesEnum.Pending) {
                console.log("Navigating to 'device requirement'");
                navigate(`/device-requirement?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }

            // Keep the user informed whilst the following tasks complete

            const createUserTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.CREATE_USER.toString());
            if (!createUserTask) {
                setErrorMessage("Create user task is missing");
                return;
            }

            if (createUserTask?.Status === TaskGroupStatusesEnum.Pending || createUserTask?.Status === TaskGroupStatusesEnum.InProgress) {
                console.log("Navigating to 'process-onboarding-task' to process task 'CREATE_USER'");
                dispatch({
                    type: OnboardingActionTypesEnum.SET_PROCESSING_TASK,
                    payload: ProjectTeamAssignmentTaskAutomationKeyEnum.CREATE_USER
                });
                navigate(`/process-onboarding-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }

            const addUserToMfaSetupAadGroupTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.ADD_USER_TO_MFA_SETUP_AAD_GROUP.toString());
            if (!createUserTask) {
                setErrorMessage("MFA group task is missing");
                return;
            }

            if (addUserToMfaSetupAadGroupTask?.Status === TaskGroupStatusesEnum.Pending || addUserToMfaSetupAadGroupTask?.Status === TaskGroupStatusesEnum.InProgress) {
                console.log("Navigating to 'process-onboarding-task' to process task 'ADD_USER_TO_MFA_SETUP_AAD_GROUP'");
                dispatch({
                    type: OnboardingActionTypesEnum.SET_PROCESSING_TASK,
                    payload: ProjectTeamAssignmentTaskAutomationKeyEnum.ADD_USER_TO_MFA_SETUP_AAD_GROUP
                });
                navigate(`/process-onboarding-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);                
                return;
            }

            const sendMfaSetupRequestEmailTask = managedProcessResult.TaskItems.find((t) => t.TaskId === ProjectTeamAssignmentTaskAutomationKeyEnum.SEND_MFA_SETUP_REQUEST_EMAIL.toString());
            if (!createUserTask) {
                setErrorMessage("Send MFA request email task is missing");
                return;
            }

            if (sendMfaSetupRequestEmailTask?.Status === TaskGroupStatusesEnum.Pending || sendMfaSetupRequestEmailTask?.Status === TaskGroupStatusesEnum.InProgress) {
                console.log("Navigating to 'process-onboarding-task' to process task 'SEND_MFA_SETUP_REQUEST_EMAIL'");
                dispatch({
                    type: OnboardingActionTypesEnum.SET_PROCESSING_TASK,
                    payload: ProjectTeamAssignmentTaskAutomationKeyEnum.SEND_MFA_SETUP_REQUEST_EMAIL
                });
                navigate(`/process-onboarding-task?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);                
                return;
            }                 

            if (sendMfaSetupRequestEmailTask?.Status === TaskGroupStatusesEnum.Complete) {
                dispatch({
                    type: OnboardingActionTypesEnum.SET_PROCESSING_TASK,
                    payload: undefined
                });

                console.log("Navigating to 'thank you'");
                navigate(`/onboarding-thank-you?id=${ManagedContext.Id}&t=${OnboardingAccessToken}`);
                return;
            }
        }
    }, [managedProcessResult, projectTeamAssignment, managedProcessLoadSuccess]);

    return (
        <S.StyledOnboardingLandingPage>
            {!errorMessage && 
                <S.LoadingSection>
                    <Spinner padding="20px" />
                    <h1>
                        {getOnboardingTaskStatusLoadingDescription(automationKey)}
                    </h1>
                </S.LoadingSection>
            }

            {errorMessage &&
                <S.ErrorWrapper>
                    <h1>
                       An error has occurred whilst determining your onboarding status
                    </h1>
                    <p>
                        {errorMessage}
                    </p>
                </S.ErrorWrapper>
            }

        </S.StyledOnboardingLandingPage>
    );
};

export default OnboardingLandingPage;
