import React, { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Text, Button, TextField, Tick, FormControl, FormErrorText, Select } from "@breakingwave/react-ui-components";
import { useTeamAssignmentStepsContext } from "../_state/context";
import { TeamAssignmentWizardStepsActionTypesEnum } from "../_state/actions";
import { TeamAssignmentWizardStepIdentifierEnum } from "../_state/types";
import { teamMemberNameStepSchema, TeamMemberNameStepInputs, ITeamMemberNameStepContext } from "../index.schema";
import useUserRoles from "../../../../../../../hooks/useUserRoles";
import useAadAccessToken from "../../../../../../../hooks/useAadAccessToken";
import { protectedResources } from "../../../../../../../auth/authConfig";
import { useProjectPageContext } from "../../../../_state/context";
import { projectTeamAssignmentBlockerReason } from "../../../../../../../utils/projectTeamAssignmentBlockerReason";
import { CreateProjectTeamAssignmentDomainValidationFailureReasonEnum } from "../../../../../../../types/managed-process/projectTeamAssignment";
import { IHomeProject } from "../../../../../../../types/projectCatalogue";
import validateProjectTeamAssignment from "../../../../../../../api/validateProjectTeamAssignment";
import teamMemberTypes from "../../../../../../../utils/teamMemberTypes";
import Column from "../../../../../../../components/grid/Column";
import Row from "../../../../../../../components/grid/Row";
import { IOption } from "../../../../../../../types/selectOption";
import { UserRolesEnum } from "../../../../../../../types/userRoles";
import * as S from "../../wizard.styles";

const TeamMemberName: React.FC = () => {
    const { userRole } = useUserRoles();
    const { getAccessToken, isAuthenticated } = useAadAccessToken(protectedResources.wavecore.scopes);
    const { state, dispatch } = useTeamAssignmentStepsContext();
    const [validatingFormWithApi, setValidatingFormWithApi] = useState(false);

    // Fully typed, shorter spelling of the step name for easy access
    const teamMemberNameStep = state[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName];
    const deviceSelectionStep = state[TeamAssignmentWizardStepIdentifierEnum.DeviceSelection];

    const teamMemberOptions = useMemo(() => {
        const options: IOption[] = [];

        Object.entries(teamMemberTypes).map(([key, name]) => {
            if (userRole === UserRolesEnum.GlobalSuperAdmin || key !== "CWR") {
                options.push({
                    value: key,
                    label: `${name} (${key})`
                });
            }
        });

        return options;
    }, [userRole]);

    const {
        state: {
            selectedProject
        }
    } = useProjectPageContext();

    const step = state[TeamAssignmentWizardStepIdentifierEnum.TeamMemberName];    

    const {
        answer: {
            teamMemberType: teamMemberTypeFromState
        }
    } = step;

    const defaultTeamMemberType = useMemo(() => {
        if (step.answer.teamMemberType) {
            const optionFromState = Object.entries(teamMemberTypes).find(([key]) => step.answer.teamMemberType === key);
            const option: IOption = { 
                label: `${optionFromState?.[1]} (${step.answer.teamMemberType})`,
                value: step.answer.teamMemberType 
            };

            return option;
        }

        return undefined;
    }, [teamMemberTypeFromState]);

    const homeProject = useMemo<IHomeProject>(() => selectedProject as IHomeProject, []);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        setError,
        setValue,
        clearErrors
    } = useForm<TeamMemberNameStepInputs, ITeamMemberNameStepContext>({
        resolver: yupResolver(teamMemberNameStepSchema),
        context: {
            userRole,
            getAccessToken,
            isAuthenticated,
            projectDomain: homeProject.ProjectDomain
        },
        defaultValues: {
            teamMemberType: "INT"
        }
    });

    const processAndGoToNext = (data: TeamMemberNameStepInputs): void => {
        const { firstName, lastName, teamMemberType, contactEmailAddress } = data;

        setValidatingFormWithApi(true);

        getAccessToken().then(async (accessToken: string) => {
            if (accessToken && isAuthenticated) {
                const result = await validateProjectTeamAssignment(
                    homeProject.ProjectSlug, 
                    {
                        FirstName: firstName,
                        LastName: lastName,
                        ContactEmailAddress: contactEmailAddress,
                        ProjectUpnDomain: homeProject.ProjectUpnDomains[0]
                    }, 
                    accessToken);

                let validAtApiLevel = true;

                const bitwise = result.ValidationResult;

                if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailAddressInUse || 
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailIsNotValidForUpnCandidate) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailIsNotValidForUpnCandidate) {
                    setError("contactEmailAddress", { message: projectTeamAssignmentBlockerReason(bitwise) });
                    validAtApiLevel = false;
                }

                if ((bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ProjectNotFound ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.ContactEmailDomainDoesNotMatchProjectDomain ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.DomainNotFound ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.InvalidDeviceType ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.TeamNotFound ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.UserPrincipalNameInUse ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.FirstNameMissing) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.FirstNameMissing ||
                    (bitwise & CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.LastNameMissing) === CreateProjectTeamAssignmentDomainValidationFailureReasonEnum.LastNameMissing) {
                    setError("firstName", { message: projectTeamAssignmentBlockerReason(bitwise) });
                    validAtApiLevel = false;
                }

                setValidatingFormWithApi(false);

                if (validAtApiLevel) {
                    dispatch({
                        type: TeamAssignmentWizardStepsActionTypesEnum.SET_TEAM_MEMBER_NAME,
                        payload: {
                            ...teamMemberNameStep,
                            answer: {
                                firstName,
                                lastName,
                                teamMemberType,
                                contactEmailAddress,
                                projectUpnDomain: homeProject.ProjectUpnDomains[0],
                                displayValue: `${firstName} ${lastName}`// (${teamMemberType})`
                            }
                        }
                    });

                    // Clear down the device selection in case the back button was hit
                    // and a device was selected that may no longer be applicable
                    dispatch({
                        type: TeamAssignmentWizardStepsActionTypesEnum.SET_DEVICE_SELECTION,
                        payload: {
                            ...deviceSelectionStep,
                            answer: {
                                deviceType: undefined
                            }
                        }
                    });                    

                    dispatch({
                        type: TeamAssignmentWizardStepsActionTypesEnum.LOAD_NEXT_STEP
                    });
                }
            }
        });
    };

    return (
        <>
            <S.QuestionWrapper>
                <Row style={{ marginLeft: "-12px"}}>
                    <Column md={9}>
                        <Text size={14} weight={500}>
                            Name
                        </Text>
                    </Column>
                    <div style={{display: "none"}}>
                        <Column>
                            <Text size={14} weight={500}>
                            Type
                            </Text> 
                        </Column>
                    </div>
                </Row>
            </S.QuestionWrapper>
            <S.AnswerWrapper>
                <Row style={{ marginLeft: "-12px"}}>
                    <Column md={9}>
                        <S.TeamMemberNameAndEmailContainer>
                            <FormControl as="fieldset">
                                <TextField
                                    {...register("firstName")}
                                    defaultValue={teamMemberNameStep.answer.firstName}
                                    placeholder="First name"
                                    error={!!errors.firstName}
                                    onChange={(e) => {
                                        setValue("firstName", e.target.value);
                                        clearErrors("firstName");
                                        clearErrors("lastName");   
                                    }}
                                />

                                {errors.firstName && <FormErrorText>{errors.firstName.message}</FormErrorText>}
                            </FormControl>
                            <FormControl as="fieldset">
                                <TextField
                                    {...register("lastName")}
                                    defaultValue={teamMemberNameStep.answer.lastName}
                                    placeholder="Last name"
                                    error={!!errors.lastName}
                                    onChange={(e) => {
                                        setValue("lastName", e.target.value);
                                        clearErrors("firstName");
                                        clearErrors("lastName");
                                    }}
                                />
                                {errors.lastName && <FormErrorText>{errors.lastName.message}</FormErrorText>}
                            </FormControl>
                            <FormControl as="fieldset" style={{ width: "135%"}}>
                                <TextField
                                    {...register("contactEmailAddress")}
                                    defaultValue={teamMemberNameStep.answer.contactEmailAddress}
                                    placeholder="Email address"
                                    error={!!errors.contactEmailAddress}
                                    onChange={(e) => {
                                        setValue("contactEmailAddress", e.target.value);  
                                        clearErrors("contactEmailAddress");    
                                    }}
                                />
                                {errors.contactEmailAddress && <FormErrorText>{errors.contactEmailAddress.message}</FormErrorText>}
                            </FormControl>
                        </S.TeamMemberNameAndEmailContainer>
                    </Column>
                    <Column>
                        <div style={{display: "none"}}>
                            <FormControl as="fieldset">
                                <Controller 
                                    name="teamMemberType"
                                    control={control}
                                    render={({field}) => (
                                        <Select 
                                            ref={field.ref}
                                            defaultValue={defaultTeamMemberType}
                                            onValueChange={(e) => {
                                                setValue("teamMemberType", e.value);
                                                clearErrors("teamMemberType");              
                                            }}
                                            placeholder="Member type"
                                            options={teamMemberOptions}
                                            error={!!errors.teamMemberType}
                                        />
                                    )}
                                />
                                {errors.teamMemberType && <FormErrorText>{errors.teamMemberType.message}</FormErrorText>}
                            </FormControl>
                        </div>
                    </Column>
                </Row>                           
            </S.AnswerWrapper>
            <S.OptionsWrapper>
                <Button
                    isLoading={validatingFormWithApi}
                    iconSvg={<Tick />}
                    iconSize={9}
                    onClick={handleSubmit(processAndGoToNext)}
                >
                    Next
                </Button>
            </S.OptionsWrapper>
        </>
    );
};

export default TeamMemberName;
