import { CreateRevokeProjectAdminRightsValidationFailureReasonEnum } from "../types/managed-process/revokeProjectAdminRights";

const revokeProjectAdminRightsValidationFailureReason = (bitwise: number): string => {

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.ProjectNotFound) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.UserWeAreTryingToRevokeIsNotAProjectAdmin) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.UserWeAreTryingToRevokeIsNotAProjectAdmin) {
        return "User is not a project admin";
    }

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightRevokeProjectAdminRights) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightRevokeProjectAdminRights) {
        return "User has an inflight revoke process";
    }   
    
    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightProjectTeamAssignments) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightProjectTeamAssignments) {
        return "User is currently onboarding";
    }
    
    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightProjectAdminAssignments) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightProjectAdminAssignments) {
        return "User is currently being assigned project admin rights";
    }

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightJoinProjects) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightJoinProjects) {
        return "User has an inflight join project";
    }

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightLeaveProjects) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightLeaveProjects) {
        return "User has an inflight leave project";
    }

    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightOffboardings) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.InFlightOffboardings) {
        return "User is currently being offboarded";
    }
    
    if ((bitwise & CreateRevokeProjectAdminRightsValidationFailureReasonEnum.ProjectAdminCannotRevokeProjectAdminRightsFromThemself) === 
            CreateRevokeProjectAdminRightsValidationFailureReasonEnum.ProjectAdminCannotRevokeProjectAdminRightsFromThemself) {
        return "You cannot revoke admin rights from yourself";
    }        

    return "Unknown error occurred";
};

export {
    revokeProjectAdminRightsValidationFailureReason
};
