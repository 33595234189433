import { CreateOffboardTeamMemberDomainValidationFailureReasonEnum } from "../types/managed-process/offboardTeamMember";

const offboardTeamMemberDomainValidationFailureReason = (bitwise: number): string => {

    if ((bitwise & CreateOffboardTeamMemberDomainValidationFailureReasonEnum.ProjectNotFound) === 
            CreateOffboardTeamMemberDomainValidationFailureReasonEnum.ProjectNotFound) {
        return "Project not found";
    }

    if ((bitwise & CreateOffboardTeamMemberDomainValidationFailureReasonEnum.NotAMemberOfProject) === 
            CreateOffboardTeamMemberDomainValidationFailureReasonEnum.NotAMemberOfProject) {
        return "Team member is not a member of this project";
    }

    if ((bitwise & CreateOffboardTeamMemberDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) === 
            CreateOffboardTeamMemberDomainValidationFailureReasonEnum.AuthenticatedUserDoesNotHavePermission) {
        return "You do not have permission to add this user";
    }

    if ((bitwise & CreateOffboardTeamMemberDomainValidationFailureReasonEnum.InFlightManagedProcesses) === 
            CreateOffboardTeamMemberDomainValidationFailureReasonEnum.InFlightManagedProcesses) {
        return "User has existing managed processes";
    }

    if ((bitwise & CreateOffboardTeamMemberDomainValidationFailureReasonEnum.ProjectAdminCannotOffboardThemselfFromProject) === 
            CreateOffboardTeamMemberDomainValidationFailureReasonEnum.ProjectAdminCannotOffboardThemselfFromProject) {
        return "You cannot offboard yourself from the project";
    }        

    return "An error occurred";
};

export {
    offboardTeamMemberDomainValidationFailureReason
};
