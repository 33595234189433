import { IMemberManagedProcessBase } from "./managedProcessBase";

export interface IRevokeProjectAdminRightsRequest {
    UserPrincipalName: string;  // The user principal name of the person we're revoking rights from
}

export interface IRevokeProjectAdminRightsResponse extends IMemberManagedProcessBase {
    BoardId: string;
    RequestedDate: Date;
    RequestedBy: string; // This is the "sub" claim from the access token.
    Status: string; // TODO: Change it to enum types. Find statuses from the state machine doc
}

export enum CreateRevokeProjectAdminRightsValidationFailureReasonEnum {
    Success = 0,
    ProjectNotFound = 1 << 0, // 1
    AuthenticatedUserDoesNotHavePermission = 1 << 1, // 2
    UserWeAreTryingToRevokeIsNotAProjectAdmin = 1 << 2, // 4
    InFlightRevokeProjectAdminRights = 1 << 3, // 8
    InFlightProjectTeamAssignments = 1 << 4, // 16
    InFlightProjectAdminAssignments = 1 << 5, // 32
    InFlightJoinProjects = 1 << 6, // 64
    InFlightLeaveProjects = 1 << 7, // 128
    InFlightOffboardings = 1 << 8, // 256
    ProjectAdminCannotRevokeProjectAdminRightsFromThemself = 1 << 9 // 512 
}

export interface IRevokeProjectAdminRightsErrorResponse {
    ValidationResult: CreateRevokeProjectAdminRightsValidationFailureReasonEnum;
}
