import styled from "styled-components";

const LoadingSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    div:first-child {
        height: auto;
    }
`;

const PageTitleSection = styled.section`
    background-color: #000;
    margin-bottom: 24px;
`;

const OverviewSection = styled.section`
    background-color: ${({ theme }) => theme.colors.whitelabel.bodySectionBackground};
    padding: 40px 0 72px;
`;

const DescriptionListWrapper = styled.div`
    margin-top: 64px;

    dl {
        display: grid;
        grid-template-columns: 224px auto;
        row-gap: 40px;
    }

    dt {
        grid-column-start: 1;
    }

    dd {
        grid-column-start: 2;
    }
`;

const AttestationSection = styled.section`
    background-color: #191818;
    padding-top: 40px;
`;

const AttestationCheckboxWrapper = styled.div`
    margin: 48px 0;
`;

const ConfirmationSection = styled.section`
    background-color: #191818;
    padding-bottom: 50px;
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    h1 {
        font-size: 1.5em;
    }
`;

// TODO: Make this one a component on its own
const Hr = styled.hr<{ type?: string; color?: string; hidden?: boolean }>`
    border-top: 1px ${({ type }) => type ?? "solid"} ${({ color }) => color ?? "#000"};
    background-color: transparent;
    visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;

export {
    LoadingSection,
    PageTitleSection,
    OverviewSection,
    DescriptionListWrapper,
    AttestationSection,
    AttestationCheckboxWrapper,
    ConfirmationSection,
    ErrorWrapper,
    Hr
};
