import { IMemberManagedProcessBase } from "./managedProcessBase";

export interface ICreateOffboardTeamMemberRequest {
    UserPrincipalName: string;  // The user principal name of the person we're offboarding
}

export interface IOffboardTeamMemberResponse extends IMemberManagedProcessBase {
    RequestedDate: Date;
    RequestedBy: string; // This is the "sub" claim from the access token.
    Status: string; // TODO: Change it to enum types. Find statuses from the state machine doc
}

export enum CreateOffboardTeamMemberDomainValidationFailureReasonEnum {
    Success = 0,
    ProjectNotFound = 1 << 0, // 1
    AuthenticatedUserDoesNotHavePermission = 1 << 1, // 2
    NotAMemberOfProject = 1 << 2, // 4
    InFlightManagedProcesses = 1 << 3, // 8
    ProjectAdminCannotOffboardThemselfFromProject = 1 << 4, // 16
}

export interface IOffboardTeamMemberErrorResponse {
    ValidationResult: CreateOffboardTeamMemberDomainValidationFailureReasonEnum;
}
