import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, FormControl, FormErrorText, Heading, Modal, Text, TextField } from "@breakingwave/react-ui-components";
import { ApprovalStatusEnum, IApprovalRequest, TaskGroupStatusesEnum } from "../../../types/shared";
import { rejectionReasonSchema, RejectionReasonInputs } from "./RejectApprovalModal.schema";
import * as ModalStyles from "../../../styles/modal.styles";
import * as S from "./RejectApprovalModal.styles";

interface IRejectApprovalModalProps {
    isRejectModalOpen: boolean;
    isNotifyAutomationServiceLoading: boolean;
    isNotifyAutomationServiceSuccess: boolean;
    confirmationTaskStatus: TaskGroupStatusesEnum | undefined;
    handleClose: () => void;
    notifyAutomationService: (payload: IApprovalRequest) => void;
}

const RejectApprovalModal: React.FC<IRejectApprovalModalProps> = ({
    isRejectModalOpen,
    isNotifyAutomationServiceLoading,
    isNotifyAutomationServiceSuccess,
    confirmationTaskStatus,
    handleClose,
    notifyAutomationService
}) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        clearErrors
    } = useForm<RejectionReasonInputs>({
        resolver: yupResolver(rejectionReasonSchema)
    });

    useEffect(() => {
        if (isRejectModalOpen) {
            clearErrors();
        }
    }, [isRejectModalOpen]);

    const submit = (data: RejectionReasonInputs): void => {
        const { details } = data;

        const request: IApprovalRequest = {
            ApprovalStatus: ApprovalStatusEnum.Rejected,
            Details: details
        };

        notifyAutomationService(request);
    };

    const waitingForRejectedTaskStatus = useMemo(() => {
        // Waiting for post to automation dispatcher
        if (isNotifyAutomationServiceLoading) {
            return true;
        }
                        
        // Waiting for Wavecore and Monday to update task to "Rejected"
        if (isNotifyAutomationServiceSuccess && confirmationTaskStatus !== TaskGroupStatusesEnum.Rejected) {
            return true;        
        }

        return false;
    }, [isNotifyAutomationServiceLoading, confirmationTaskStatus]);

    return (
        <Modal 
            isOpen={isRejectModalOpen}
            width={700}
            handleClose={handleClose}>
            <ModalStyles.ModalHeader>
                <Heading variant="h5">
                    Reject Request
                </Heading>
            </ModalStyles.ModalHeader>
                
            <ModalStyles.ModalContent>
                <Text variant="p" size={14} style={{ marginBottom: "15px" }}>
                    Please enter a reason for rejecting the request below
                </Text>
                <FormControl as="fieldset">
                    <TextField 
                        disabled={isNotifyAutomationServiceLoading || isNotifyAutomationServiceSuccess}
                        width={250}
                        {...register("details")}
                        error={!!errors.details}
                        onChange={(e) => {
                            setValue("details", e.target.value);
                            clearErrors("details");
                            clearErrors("details");   
                        }}
                    />
                    {errors.details && <FormErrorText>{errors.details.message}</FormErrorText>}
                </FormControl>
            </ModalStyles.ModalContent>

            <ModalStyles.ModalFooter>
                <S.RejectModalFooter>
                    {isNotifyAutomationServiceSuccess && confirmationTaskStatus === TaskGroupStatusesEnum.Rejected ?
                        <Text variant="p" size={14}>
                            You have rejected the requested
                        </Text>                        
                        : <div></div>}
                    <S.RejectModalFooterButtons>
                        <Button 
                            onClick={handleClose} 
                            disabled={waitingForRejectedTaskStatus}
                            variant="primary">
                            {isNotifyAutomationServiceSuccess && !waitingForRejectedTaskStatus ? "Close" : "Cancel"}                    
                        </Button>
                        {(!isNotifyAutomationServiceSuccess || waitingForRejectedTaskStatus) &&
                        <Button 
                            onClick={handleSubmit(submit)}
                            disabled={waitingForRejectedTaskStatus}
                            isLoading={waitingForRejectedTaskStatus}
                            variant="primary">
                            Reject
                        </Button>
                        }                
                    </S.RejectModalFooterButtons>
                </S.RejectModalFooter>
            </ModalStyles.ModalFooter>
        </Modal>        
    );

};

export default RejectApprovalModal;
