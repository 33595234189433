import styled from "styled-components";

const ModalHeader = styled.div`
    background-color: ${({theme}) => theme.colors.whitelabel.modalBackground};
    padding: 20px;
`;

const ModalContent = styled.div`
    background-color: ${({theme}) => theme.colors.whitelabel.modalBackgroundAlt};
    color: ${({theme}) => theme.colors.whitelabel.modalForeground};
    padding: 20px;
`;

const ModalFooter = styled.div`
    background-color: ${({theme}) => theme.colors.whitelabel.modalBackground};
    padding: 20px;
`;

export {
    ModalHeader,
    ModalContent,
    ModalFooter
};
