import { useMemo } from "react";
import { UserTypeEnum } from "../types/shared";
import { IProjectTeamMemberDisplayItemsResponse } from "../types/teamMemberDisplayItems";
import { ProjectTeamMemberStateEnum } from "../types/managed-process/projectTeamAssignment";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const useUserType = (teamMember: IProjectTeamMemberDisplayItemsResponse) => {

    const {
        IsProjectAdmin,
        SubscriptionType,
        State
    } = teamMember;

    const userType = useMemo(() => {

        const adminAssignment = State === ProjectTeamMemberStateEnum.AdminAssignment;

        if (adminAssignment || IsProjectAdmin) {           
            return UserTypeEnum.UserAndProjectAdmin;
        }
        
        return UserTypeEnum.User;

    }, [IsProjectAdmin, SubscriptionType, State]);

    return {
        userType
    };
};

export default useUserType;
