import { useEffect } from "react";
import { AppActionTypesEnum, useAppContext } from "../App/_state/context";
import { IManagedProcessBase } from "../types/managed-process/managedProcessBase";

const useWaveTheme = (managedProcess: IManagedProcessBase | undefined): null => {

    const {
        dispatch: appDispatch
    } = useAppContext();

    useEffect(() => {
        if (managedProcess) {
            appDispatch({
                type: AppActionTypesEnum.SET_THEME,
                payload: managedProcess.ThemeName
            });
        }
    }, [managedProcess]);

    return null;
};

export default useWaveTheme;
