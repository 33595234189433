/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { IProjectTeamAssignmentResponse } from "../../../../types/managed-process/projectTeamAssignment";
import { ProjectTeamAssignmentTaskAutomationKeyEnum } from "../../../../types/onboarding";
import { OnboardingActionTypesEnum } from "./actions";
import { initialOnboardingState } from "./initialState";
import { IOnboardingState } from "./types";

export interface ISetOnboardingContextAction {
    type: OnboardingActionTypesEnum.SET_ONBOARDING_CONTEXT;
    payload: IOnboardingState["ManagedContext"];
}

export interface ISetDeviceRequest {
    type: OnboardingActionTypesEnum.SET_DEVICE_REQUEST;
    payload: IOnboardingState["DeviceRequest"];
}

export interface ISetOnboardingAccessToken {
    type: OnboardingActionTypesEnum.SET_ONBOARDING_ACCESSTOKEN;
    payload: string;
}

export interface ISetOnboardingManagedProcess {
    type: OnboardingActionTypesEnum.SET_ONBOARDING_MANAGED_PROCESS;
    payload: IProjectTeamAssignmentResponse | undefined;
}

export interface ISetProcessingTask {
    type: OnboardingActionTypesEnum.SET_PROCESSING_TASK;
    payload: ProjectTeamAssignmentTaskAutomationKeyEnum | undefined;
}

export type OnboardingActions =
    | ISetOnboardingContextAction
    | ISetDeviceRequest
    | ISetOnboardingAccessToken
    | ISetOnboardingManagedProcess
    | ISetProcessingTask;

export const reducer: React.Reducer<IOnboardingState, OnboardingActions> = (state, action) => {
    switch (action.type) {
        case OnboardingActionTypesEnum.SET_ONBOARDING_CONTEXT: {
            return {
                ...state,
                ManagedContext: action.payload
            };
        }
        case OnboardingActionTypesEnum.SET_DEVICE_REQUEST: {
            return {
                ...state,
                DeviceRequest: action.payload
            };
        }
        case OnboardingActionTypesEnum.SET_ONBOARDING_ACCESSTOKEN: {
            return {
                ...state,
                AccessToken: action.payload
            };
        }
        case OnboardingActionTypesEnum.SET_ONBOARDING_MANAGED_PROCESS: {
            return {
                ...state,
                ManagedProcess: action.payload
            };      
        }
        case OnboardingActionTypesEnum.SET_PROCESSING_TASK: {
            return {
                ...state,
                ProcessingTask: action.payload
            };      
        }        

        default:
            return state;
    }
};

export interface IOnboardingContextProps {
    state: IOnboardingState;
    dispatch: React.Dispatch<OnboardingActions>;
}

export const OnboardingContext = React.createContext<IOnboardingContextProps>({
    state: initialOnboardingState,
    dispatch: () => null
});

export function useOnboardingContext (): IOnboardingContextProps {
    return React.useContext(OnboardingContext);
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const OnboardingProvider = ({ children }: any) => {
    const [state, dispatch] = React.useReducer(reducer, initialOnboardingState);
    const value = { state, dispatch };
    return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};
