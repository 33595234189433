import styled from "styled-components";

const RejectModalFooter = styled.div`
    display: flex;
    justify-content: space-between;
`;

const RejectModalFooterButtons = styled.div`
    display: flex;
    gap: 15px;    
`;

export {
    RejectModalFooter,
    RejectModalFooterButtons
};
